<template>
    <div>
        <div class="error-box">
            <div class="error-title">抱歉，你访问的页面有误！</div>
            <div class="error-content">
                <p>访问的链接有误。</p>
            </div>
        </div>
    </div>
</template>
  
<script>
import TitleBar from "@/components/MT/TitleBar";

export default {
    name: "MTModule",
    components: {

    },
};
</script>
<style scoped>
.error-box {
    width: 100%;
    margin-top: 8%;
    text-align: center;
}

.error-code {
    font-size: 20px;
    font-weight: 700;
    color: #999;
}

.error-title {
    margin-bottom: 2vh;
    font-size: 18px;
    font-weight: 600;
    color: #ffa53b;
}

.error-content {
    margin-bottom: 2vh;
    font-size: 10px;
    font-weight: 400;
    color: #999;
}
</style>